<template>
    <div class="header" :class="{ 'page-home': $route.meta.isPageHome, 'fixed-header': store.state.scrollPosition > 300 }">
        <div class="header__wrap">
            <div class="header__content container">
                <div class="header__logo">
                    <img src="@/assets/image/logo/logo-dicol_white.png" alt="Dicol" @click="changeRouter('Home')" v-if="$route.meta.isPageHome" />
                    <img src="@/assets/image/logo/logo-dicol_black.png" alt="Dicol" @click="changeRouter('Home')" v-else />
                </div>
                <ul class="header__menu">
                    <li><router-link :to="{ name: 'construction' }">Công Trình</router-link></li>
                    <li><router-link :to="{ name: 'contact' }">Liên Hệ</router-link></li>
                </ul>
            </div>
        </div>
<!--        <div class="header__fixed-call">-->
<!--            <a href="tel:0905090090">-->
<!--                <img v-if="$route.meta.isPageHome" src="@/assets/image/icon/icon-call_white.png" alt="icon call" />-->
<!--                <img v-else src="@/assets/image/icon/icon-call_black.png" alt="icon call" />-->
<!--            </a>-->
<!--        </div>-->
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const changeRouter = (nameRouter) => {
    router.push({ name: nameRouter });
};
</script>
<style lang="scss" scoped src="@/assets/scss/header/_index.scss"></style>
